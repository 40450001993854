// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-approve-vasp-jsx": () => import("./../../../src/pages/approve-vasp.jsx" /* webpackChunkName: "component---src-pages-approve-vasp-jsx" */),
  "component---src-pages-approve-vasp-list-jsx": () => import("./../../../src/pages/approve-vasp-list.jsx" /* webpackChunkName: "component---src-pages-approve-vasp-list-jsx" */),
  "component---src-pages-bundle-service-vasp-jsx": () => import("./../../../src/pages/bundle-service-vasp.jsx" /* webpackChunkName: "component---src-pages-bundle-service-vasp-jsx" */),
  "component---src-pages-bundle-service-vasp-list-jsx": () => import("./../../../src/pages/bundle-service-vasp-list.jsx" /* webpackChunkName: "component---src-pages-bundle-service-vasp-list-jsx" */),
  "component---src-pages-create-vasp-jsx": () => import("./../../../src/pages/create-vasp.jsx" /* webpackChunkName: "component---src-pages-create-vasp-jsx" */),
  "component---src-pages-create-vasp-update-request-jsx": () => import("./../../../src/pages/create-vasp-update-request.jsx" /* webpackChunkName: "component---src-pages-create-vasp-update-request-jsx" */),
  "component---src-pages-download-report-jsx": () => import("./../../../src/pages/download-report.jsx" /* webpackChunkName: "component---src-pages-download-report-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-market-finder-jsx": () => import("./../../../src/pages/market-finder.jsx" /* webpackChunkName: "component---src-pages-market-finder-jsx" */),
  "component---src-pages-monitoring-list-jsx": () => import("./../../../src/pages/monitoring-list.jsx" /* webpackChunkName: "component---src-pages-monitoring-list-jsx" */),
  "component---src-pages-monitoring-vasp-jsx": () => import("./../../../src/pages/monitoring-vasp.jsx" /* webpackChunkName: "component---src-pages-monitoring-vasp-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-registration-jsx": () => import("./../../../src/pages/registration.jsx" /* webpackChunkName: "component---src-pages-registration-jsx" */),
  "component---src-pages-review-created-vasp-jsx": () => import("./../../../src/pages/review-created-vasp.jsx" /* webpackChunkName: "component---src-pages-review-created-vasp-jsx" */),
  "component---src-pages-review-vasp-update-request-jsx": () => import("./../../../src/pages/review-vasp-update-request.jsx" /* webpackChunkName: "component---src-pages-review-vasp-update-request-jsx" */),
  "component---src-pages-vasp-list-jsx": () => import("./../../../src/pages/vasp-list.jsx" /* webpackChunkName: "component---src-pages-vasp-list-jsx" */),
  "component---src-pages-vasp-regulation-jsx": () => import("./../../../src/pages/vasp-regulation.jsx" /* webpackChunkName: "component---src-pages-vasp-regulation-jsx" */),
  "component---src-pages-vasp-regulation-list-jsx": () => import("./../../../src/pages/vasp-regulation-list.jsx" /* webpackChunkName: "component---src-pages-vasp-regulation-list-jsx" */),
  "component---src-pages-vasp-update-request-list-jsx": () => import("./../../../src/pages/vasp-update-request-list.jsx" /* webpackChunkName: "component---src-pages-vasp-update-request-list-jsx" */)
}

